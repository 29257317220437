import React from 'react';
import classNames from 'classnames';
import { useStaticQuery, graphql, Link } from 'gatsby';
// import Img from "gatsby-image/withIEPolyfill";

/**
 * Assets
 */
import './assets/styles/_index.scss';
// import Heart from '@static/svg/heart-icon.svg';


const Property = ({property, className}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  //const currentImage = data.allFile.edges.filter(item => item.node.base === property.image.split('/').pop()).pop().node.childImageSharp.fluid;

  return (
    <div className={classNames('property', className)}>
      <Link to={property.itemUrl}>
        <div className="property__image">
        {/* <Img
            fluid={currentImage}
          /> */}
          <img src={property.image.srcUrl} alt={property.location}/>
          {/* <div className="property__heart"><Heart /></div> */}
        </div>
        <h5>{property.price}</h5>
        <h6>{property.location}</h6>
        <p>{property.description}</p>
        </Link>
    </div>
  )
}

export default Property;